<template>
  <div id="box999">
    <div class="box">
      <div class="header-box">
        <ul class="head">
          <li id="logo">
            <img class="img" src="~@/assets/img/Slice 19.png" @click="ToHomepage" alt="" />
          </li>
        </ul>
        <ul class="head" style="margin-right: 15px">
          <li v-if="userInfo.teamId" class="nav" @click="handleIcon">
            <div class="FristPage">
              印刷管家
            </div>
            <img class="icon-order" src="@/assets/img/icon-order.png" alt="">
          </li>
        </ul>

        <div @click="handleIcon1" class="right-box">
          <!--          <div class="name">-->
          <!--            <span style="color:#F3A000">{{ userInfo.userName }}</span>-->
          <!--          </div>-->
          <!--          <el-tooltip class="item" effect="dark" :content="userInfo.userName" placement="bottom">-->
          <!--            <div class="name">-->
          <!--&lt;!&ndash;              您好！用户&ndash;&gt;-->
          <!--&lt;!&ndash;              <span style="color:#F3A000">{{ userInfo.userName }}</span>&ndash;&gt;-->
          <!--            </div>-->
          <!-- </el-tooltip> -->
          <el-dropdown>
            <div class="block">
              <el-avatar
                  :size="30"
                  :src="defaultUrl ? defaultUrl : require(`@/assets/img/headerImg.png`)"
              ></el-avatar>
              <!--              <el-avatar :size="54" :src="require(`@/assets/img/headerImg.png`)"></el-avatar>-->
            </div>
            <el-dropdown-menu slot="dropdown" style="width: 150px;padding: 10px 0px 0px;font-size: 12px">
              <ol>
                <li class="li-text li1" @click="exit">
                  <div style="display: flex;padding: 10px 24px; margin-bottom: 10px">
                    <div style="padding-right: 11px;">
                      <img src="@/assets/img/Slice 37.png" alt="img" />
                    </div>
                    退出登录
                  </div>
                </li>
              </ol>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="menu-app" :class="iconImg === true ? 'activeImg' : ''">
          <ul class="app-ul">
            <li>
              <router-link :to="`/PrintSteward/PrintHome`">
                <img src="~@/assets/img/icon-home1.png" alt="">
                主页
              </router-link>
            </li>
            <li >
              <router-link :to="`/PrintSteward/boss`">
                <img src="~@/assets/img/icon-home2.png" alt="">
                大屏展示老板
              </router-link>
            </li>
            <li>
              <router-link :to="`/PrintSteward/workshop`">
                <img src="~@/assets/img/icon-home2.png" alt="">
                大屏展示车间
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div @mouseenter="handleMouse" @mouseleave="handleLeave" class="title-top">
        <div class="title" @mouseenter="handleMouse" @mouseleave="handleLeave">印刷管家数据运营大屏</div>
        <div class="title fullScreen" @mouseenter="handleMouse" @mouseleave="handleLeave" :class="{active: fullScreenTrue === true }" style="position: absolute;right: 30%; top: 30px" @click="click">
          <img src="@/assets/img/fullscreenBig.png" v-if="isFullscreen" alt="">
          <img src="@/assets/img/screenful.png" v-if="!isFullscreen" alt="">
          <!--      <svg-icon :icon-class="isFullscreen?'exit-fullscreen':'fullscreen'" @click="click" />-->
        </div>
        <div class="time">
          {{ nowDate }}
        </div>
        <div class="timeYear">
          {{ nowDateList }}
        </div>
        <div class="timeWeek">
          {{ nowDateWeek }}
        </div>
      </div>

      <div class="boss">
          <div class="echarts-box" @mouseenter="handleMouse" @mouseleave="handleLeave">
            <div class="max-width" >
              <div class="title-module title-module10">
                <div class="width-120">印刷机有效时间稼动率</div>
                <div class="width-300">PRINTING MACHINE TIME OPERATION RATE</div>
              </div>
              <div class="echarts-back">
                <div class="day7">近7日趋势图</div>
                <BarChart3
                    v-if="chartData.source.length > 0"
                    :id="'chartData1'"
                    :data="chartData"
                    :height="230"
                    :legendShow="true"
                    :gridBottom="40"
                    :legend="this.chartDataTime.legend"
                    :xData="this.chartDataTime.xData"
                    :gridLeft="40"
                    :gridRight="40"
                    :gridTop="40"
                />
              </div>
            </div>
            <div class="max-width30" >
              <div class="title-module2 title-module10">
                <div class="width-120">周有效时间稼动率</div>
                <div class="width-300">CYCLE TIME DYNAMIC RATE</div>
              </div>
              <div class="echarts-back">
                <div class="paper">
                  <div class="contrast">
                    相比上周
                    <div :style="{'color': formDate.timeOeeWeekUp > 0 ? '#3173F1' : '#FE981D'}" style="display:block;">
                      {{ formDate.timeOeeWeekUp ? Math.abs(formDate.timeOeeWeekUp) : formDate.timeOeeWeekUp === 0 ? 0 : '--' }}%
                      <img v-if="formDate.timeOeeWeekUp > 0" src="@/assets/img/arrows2.png" alt="">
                      <img v-if="formDate.timeOeeWeekUp < 0" src="@/assets/img/arrows1.png" alt="">
                    </div>
                  </div>
                  <div style="width: 92%;">
                    <div class="score-time">
                      <div class="width-24">本周</div>
                      <el-progress class="blue" style="width: 81%;" :stroke-width="12" color="#3E7CF4" :text-color="color" :text-inside="true" :percentage="formDate.timeOeeWeek" />
                    </div>
                    <div class="score-time">
                      <div class="width-24">上周</div>
                      <el-progress class="yellow" style="width: 81%;" :stroke-width="12" color="#FE971B" :text-color="color" :text-inside="true" :percentage="formDate.timeOeeWeekLast" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="title-module2 title-module10">
                <div class="width-120">月有效时间稼动率</div>
                <div class="width-300">MONTHLY TIME DYNAMIC RATE</div>
              </div>
              <div class="echarts-back">
                <div class="paper">
                  <div class="contrast">
                    相比上月
                    <div :style="{'color': formDate.timeOeeMonthUp > 0 ? '#3173F1' : '#FE981D'}" style="display:block;">{{ formDate.timeOeeMonthUp ? Math.abs(formDate.timeOeeMonthUp) : formDate.timeOeeMonthUp === 0 ? 0 : '--' }}%
                      <img v-if="formDate.timeOeeMonthUp > 0" src="@/assets/img/arrows2.png" alt="">
                      <img v-if="formDate.timeOeeMonthUp < 0" src="@/assets/img/arrows1.png" alt="">
                    </div>
                  </div>
                  <div style="width: 92%;">
                    <div class="score-time">
                      <div class="width-24">本月</div>
                      <el-progress class="blue" style="width: 81%;" :stroke-width="12" color="#3E7CF4" :text-color="color" :text-inside="true" :percentage="formDate.timeOeeMonth" />
                    </div>
                    <div class="score-time">
                      <div class="width-24">上月</div>
                      <el-progress class="yellow" style="width: 81%;" :stroke-width="12" color="#FE971B" :text-color="color" :text-inside="true" :percentage="formDate.timeOeeMonthLast" />
                    </div>
                  </div>
                </div>
                <div class="info">{{ formDate.timeOeeMonthLast }}%{{formDate.timeOeeMonth - formDate.timeOeeMonthLast > 0 ? '提升' : '下降'}}到{{ Math.abs((Number(formDate.timeOeeMonth)).toFixed(2)) }}%，{{formDate.timeOeeMonth - formDate.timeOeeMonthLast > 0 ? '提升' : '下降'}}{{ Math.abs(((Number(formDate.timeOeeMonth) + Number(formDate.timeOeeMonthUp)) - formDate.timeOeeMonth ).toFixed(2)) }}%相当于每天{{formDate.timeOeeMonthUpCount > 0 ? '多印' : '少印'}}{{ Math.abs(formDate.timeOeeMonthUpCount) }}张</div>
              </div>
            </div>
          </div>
          <div class="echarts-box">
            <div class="max-width" >
              <div class="title-module title-module10">
                <div class="width-120">印刷机性能稼动率</div>
                <div class="width-300">PRINTING MACHINE PERFORMANCE OPERATION RATE</div>
              </div>
              <div class="echarts-back">
                <div class="day7">近7日趋势图</div>
                  <BarChart3
                      v-if="chartData2.source.length > 0"
                      :id="'chartData2'"
                      :data="chartData2"
                      :height="230"
                      :legendShow="true"
                      :gridBottom="40"
                      :legend="this.chartDataTime2.legend"
                      :xData="this.chartDataTime2.xData"
                      :gridLeft="40"
                      :gridRight="40"
                      :gridTop="40"
                  />
              </div>
            </div>
            <div class="max-width30">
              <div class="title-module2 title-module10">
                <div class="width-120">周性能稼动率</div>
                <div class="width-300">CYCLIC PERFORMANCE DYNAMIC RATE</div>
              </div>
              <div class="echarts-back">
                <div class="paper">
                  <div class="contrast">
                    相比上周
                    <div :style="{'color': formDate.capacityOeeWeekUp > 0 ? '#3173F1' : '#FE981D'}" style="display:block;">{{ formDate.capacityOeeWeekUp ? Math.abs(formDate.capacityOeeWeekUp) : formDate.capacityOeeWeekUp === 0 ? 0 : '--' }}%
                      <img v-if="formDate.capacityOeeWeekUp > 0" src="@/assets/img/arrows2.png" alt="">
                      <img v-if="formDate.capacityOeeWeekUp < 0" src="@/assets/img/arrows1.png" alt="">
                    </div>
                  </div>
                  <div style="width: 92%;">
                    <div class="score-time">
                      <div class="width-24">本周</div>
                      <el-progress class="blue" style="width: 81%;" :stroke-width="12" color="#3E7CF4" :text-color="color" :text-inside="true" :percentage="formDate.capacityOeeWeek" />
                    </div>
                    <div class="score-time">
                      <div class="width-24">上周</div>
                      <el-progress class="yellow" style="width: 81%;" :stroke-width="12" color="#FE971B" :text-color="color" :text-inside="true" :percentage="formDate.capacityOeeWeekLast" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="title-module2 title-module10">
                <div class="width-120">月性能稼动率</div>
                <div class="width-300">MONTHLY PERFORMANCE DYNAMIC RATE</div>
              </div>
              <div class="echarts-back">
                <div class="paper">
                  <div class="contrast">
                    相比上月
                    <div :style="{'color': formDate.capacityOeeMonthUp > 0 ? '#3173F1' : '#FE981D'}" style="display:block;">{{ formDate.capacityOeeMonthUp ? Math.abs(formDate.capacityOeeMonthUp) : formDate.capacityOeeMonthUp === 0 ? 0 : '--' }}%
                      <img v-if="formDate.capacityOeeMonthUp > 0" src="@/assets/img/arrows2.png" alt="">
                      <img v-if="formDate.capacityOeeMonthUp < 0" src="@/assets/img/arrows1.png" alt="">
                    </div>
                  </div>
                  <div style="width: 92%;">
                    <div class="score-time">
                      <div class="width-24">本月</div>
                      <el-progress class="blue" style="width: 81%;" :stroke-width="12" color="#3E7CF4" :text-color="color" :text-inside="true" :percentage="formDate.capacityOeeMonth" />
                    </div>
                    <div class="score-time">
                      <div class="width-24">上月</div>
                      <el-progress class="yellow" style="width: 81%;" :stroke-width="12" color="#FE971B" :text-color="color" :text-inside="true" :percentage="formDate.capacityOeeMonthLast" />
                    </div>
                  </div>
                </div>
                <div class="info">{{ formDate.capacityOeeMonthLast }}% {{formDate.capacityOeeMonth - formDate.capacityOeeMonthLast > 0 ? '提升' : '下降'}}到{{ Math.abs((Number(formDate.capacityOeeMonth)).toFixed(2)) }}%，{{formDate.capacityOeeMonth - formDate.capacityOeeMonthLast > 0 ? '提升' : '下降'}}{{ Math.abs((Number(formDate.capacityOeeMonthUp)).toFixed(2)) }}%相当于每天{{formDate.capacityOeeMonthUpCount > 0 ? '多印' : '少印'}}{{ Math.abs(formDate.capacityOeeMonthUpCount)
                  }}张</div>
              </div>
            </div>
          </div>
          <div class="echarts-box">
            <div class="max-width43">
              <div class="title-module4 title-module10">
                <div>色彩质量</div>
                <div>COLOR QUALITY</div>
              </div>
              <div class="echarts-back height-screen" style="border: 0;padding: 10px;height: 220px">
                <div class="score scoreStyle">
                  <el-tooltip class="item" effect="dark" :content="formDate.monthScore" placement="top-end">
                    <div class="score-item" style="position: relative;height: 200px;">
                      <div :style="{'color': formDate.monthScore >= formDate.upperMonthScore ? '#3173F1' : '#FE981D'}" class="width-number">
                        {{ formDate.monthScore }}
                      </div>
                      <img class="img-top" v-if="formDate.monthScore > formDate.upperMonthScore" src="@/assets/img/arrows2.png" alt="">
                      <img class="img-top" v-if="formDate.monthScore < formDate.upperMonthScore" src="@/assets/img/arrows1.png" alt="">
                      <div class="posi">
                        本月得分
                      </div>
                    </div>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" :content="formDate.upperMonthScore" placement="top-end">
                    <div class="score-item" style="height: 200px;"><div class="width-number">{{ formDate.upperMonthScore }}</div>
                      <div class="posi">
                      上月得分
                      </div>
                    </div>
                  </el-tooltip>
                    <el-tooltip class="item" effect="dark" :content="formDate.weekScore" placement="top-end">
                     <div class="score-item" style="position: relative;height: 200px;">
                       <div :style="{'color': formDate.weekScore >= formDate.upperWeekScore ? '#3173F1' : '#FE981D'}" class="width-number">
                       {{ formDate.weekScore }}

                      </div>
                       <img class="img-top" v-if="formDate.weekScore > formDate.upperWeekScore" src="@/assets/img/arrows2.png" alt="">
                       <img class="img-top" v-if="formDate.weekScore < formDate.upperWeekScore" src="@/assets/img/arrows1.png" alt="">
                       <div class="posi">
                         本周得分
                       </div>
                     </div>
                    </el-tooltip>
                        <el-tooltip class="item" effect="dark" :content="formDate.upperWeekScore" placement="top-end">
                        <div class="score-item" style="height: 200px;"><div class="width-number">{{ formDate.upperWeekScore }}</div>
                          <div class="posi">
                          上周得分
                          </div>
                        </div>
                        </el-tooltip>
                  <el-tooltip class="item" effect="dark" :content="String(formDate.firstScore ? formDate.firstScore : '--')" placement="top-end">
                    <div class="score-item" style="height: 200px;"><div style="color: #33C389" class="width-number">{{ formDate.firstScore ? formDate.firstScore : '--' }}</div>
                      <div class="posi">
                      项目入场
                      </div>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <div class="max-width29">
              <div class="title-module3 title-module10">
                <div>色彩质量趋势</div>
                <div>COLOR QUALITY TREND</div>
              </div>
              <div class="score echarts-back" style="width: 100%;border: 0;height: 222px;">
                <div class="subhead">近7日趋势图</div>
                <LineChart
                    v-if="chartData3.source.length > 0"
                    :id="'chartData3'"
                    :data="chartData3"
                    :height="193"
                    :legendShow="false"
                    :yAxisName="'分'"
                    :gridTop="30"
                />
                <div v-else style="line-height: 193px;text-align: center;width: 100%;">
                  暂无数据
                </div>
              </div>
            </div>
            <div class="max-width301">
              <div class="title-module2 title-module10">
                <div style="display: flex;align-items: center">设备状态 &nbsp;<span style="font-size: 14px">(水墨平衡)</span></div>
                <div>DEVICE STATUS</div>
              </div>
              <div class="echarts-box" style="height: 225px;">
                <div class="quality-box echarts-back">
                  <div class="scoreStyle scoreStyle2" style="overflow-y: hidden;
  text-overflow: ellipsis; white-space: nowrap;
  text-align: right;position: relative">
                    <div style="position: relative">
                      <echartsWater
                          v-if="formDate.deviceMonthScore >= 0"
                          :id="'chartData4'"
                          :gridLeft="formDate.deviceMonthScore"
                          :formDate="'本月得分'"
                          :color="'#417EF4'"
                      >
                      </echartsWater>
                      <div>
                        <img style="width: 130px;height: 130px;left: 10px;" class="quan1" src="@/assets/img/quan3.png" alt="">
                      </div>
                    </div>

                    <div style="position: relative">
                      <echartsWater
                          v-if="formDate.deviceUpperMonthScore >= 0"
                          :id="'chartDataState1'"
                          :gridLeft="formDate.deviceUpperMonthScore"
                          :formDate="'上月得分'"
                          :color="'#FD991F'"
                      >
                      </echartsWater>
                      <img style="width: 130px;height: 130px;left: 10px;;" class="quan2" src="@/assets/img/quan1.png" alt="">
                    </div>
                    <div style="position: relative">
                      <div v-if="formDate.deviceFirstScore >= 0">
                        <echartsWater
                            v-if="formDate.deviceFirstScore >= 0"
                            :id="'chartDataState2'"
                            :gridLeft="formDate.deviceFirstScore"
                            :formDate="'项目入场'"
                            :color="'#33C389'"
                        >
                        </echartsWater>
                      </div>
                      <!--                    <div class="null" v-if="formDate.deviceFirstScore === null">-->
                      <img v-if="formDate.deviceFirstScore === null" style="width: 130px;height: 130px;left: 10px;" class="null" src="@/assets/img/null.png" alt="">
                      <!--                    </div>-->
                      <img style="width: 130px;height: 130px;left: 10px;" class="quan3" src="@/assets/img/quan2.png" alt="">
                    </div>

<!--                    <div v-if="!formDate.deviceMonthScore || !formDate.deviceUpperMonthScore || !formDate.deviceFirstScore" style="line-height: 220px;text-align: center;width: 100%;">-->
<!--                      暂无数据-->
<!--                    </div>-->
                  </div>
                </div>
              </div>
            </div>
            <div class="max-width302">
              <div class="title-module2 title-module10">
                <div style="display: flex;align-items: center">设备状态 &nbsp;<span style="font-size: 14px">(水墨平衡)</span></div>
                <div>DEVICE STATUS</div>
              </div>
              <div class="echarts-box" style="height: 200px;">
                <div class="quality-box echarts-back">
                  <div class="scoreStyle scoreStyle2" style="overflow-y: hidden;
  text-overflow: ellipsis; white-space: nowrap;
  text-align: right;position: relative;justify-content: space-evenly">
                    <div style="position: relative">
                      <echartsWater3
                          v-if="formDate.deviceMonthScore >= 0"
                          :id="'chartData14'"
                          :gridLeft="formDate.deviceMonthScore"
                          :formDate="'本月得分'"
                          :color="'#417EF4'"
                      >
                      </echartsWater3>
                      <div>
                        <img style="width: 105px;height: 105px;left: 2px;" class="quan1" src="@/assets/img/quan3.png" alt="">
                      </div>
                    </div>

                    <div style="position: relative">
                      <echartsWater3
                          v-if="formDate.deviceUpperMonthScore >= 0"
                          :id="'chartDataState11'"
                          :gridLeft="formDate.deviceUpperMonthScore"
                          :formDate="'上月得分'"
                          :color="'#FD991F'"
                      >
                      </echartsWater3>
                      <img style="width: 105px;height: 105px;left: 2px;;" class="quan2" src="@/assets/img/quan1.png" alt="">
                    </div>
                    <div style="position: relative">
                      <div v-if="formDate.deviceFirstScore >= 0">
                        <echartsWater3
                            v-if="formDate.deviceFirstScore >= 0"
                            :id="'chartDataState12'"
                            :gridLeft="formDate.deviceFirstScore"
                            :formDate="'项目入场'"
                            :color="'#33C389'"
                        >
                        </echartsWater3>
                      </div>
                      <!--                    <div class="null" v-if="formDate.deviceFirstScore === null">-->
                      <img v-if="formDate.deviceFirstScore === null" style="width: 105px;height: 105px;left: 2px;" class="null" src="@/assets/img/null.png" alt="">
                      <!--                    </div>-->
                      <img v-if="formDate.deviceFirstScore !== null" style="width: 105px;height: 105px;left: 2px;" class="quan3" src="@/assets/img/quan2.png" alt="">
                    </div>

                    <!--                    <div v-if="!formDate.deviceMonthScore || !formDate.deviceUpperMonthScore || !formDate.deviceFirstScore" style="line-height: 220px;text-align: center;width: 100%;">-->
                    <!--                      暂无数据-->
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull';
import BarChart from '@/components/Charts/BarChart'; // 图表组件
import BarChart3 from '@/components/Charts/BarChart3'; // 图表组件
import LineChart from '@/components/Charts/LineChart'; // 线性组件
import echartsWater from '@/components/Charts/LineChartWater2'; // 线性组件
import echartsWater3 from '@/components/Charts/LineChartWater3'; // 线性组件
import API from '@/api';
import cookies from "@/utils/cookies";
import {store} from "@/store";
import {isEmail} from "@/utils/validate";
export default {
name: "boss",
  components: { BarChart, LineChart, BarChart3, echartsWater, echartsWater3 },
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (value !== this.param.newPassword1) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      iconImg: false,
      formDate: {
        timeOee: [],
        capacityOee: [],
        scoreList: [],
        timeOeeWeekUp: undefined,
        timeOeeWeek: undefined,
        timeOeeWeekLast: undefined,
        timeOeeMonth: undefined,
        timeOeeMonthLast: undefined,
        timeOeeMonthUp: undefined,
        capacityOeeWeekUp: undefined,
        capacityOeeWeek: undefined,
        capacityOeeWeekLast: undefined,
        capacityOeeMonth: undefined,
        capacityOeeMonthLast: undefined,
        capacityOeeMonthUp: undefined,
        monthScore: undefined,
        weekScore: undefined,
        upperMonthScore: undefined,
        upperWeekScore: undefined,
        firstScore: undefined,
      },
      fullScreenTrue: false,
      isFullscreen: true,
      nowDate:'',
      nowDateList: '',
      nowDateWeek: '',
      color: '#3E7CF4',
      // 图表
      chartData: {
        // 数据集
        source: [],
        series: [
          {
            type: 'line',
            barWidth: 30,
          },
          {
            type: 'line',
            barWidth: 30,
          },
          {
            type: 'line',
            barWidth: 30,
          }
        ],
      },
      chartData2: {
        // 数据集
        source: [],
        // 系列
        series: [
          {
            type: 'line',
            barWidth: 30,
          },
          {
            type: 'line',
            barWidth: 30,
          },
          {
            type: 'line',
            barWidth: 30,
          }
        ],
      },
      chartData4:{
        source: [],
        // 系列
        series: [],
      },
      chartDataTime: {
        source: [],
        series: [],
        legend: [],
        xData: []
      },
      chartDataTime2: {
        source: [],
        series: [],
        legend: [],
        xData: []
      },
      chartData3: {
        source: [],
        // 系列
        series: [
          { type: 'line' },
        ],
      },
      // 柱形颜色
      barColor: [
        // eslint-disable-next-line new-cap,no-new-require
        new require('echarts').graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#FA460D' },
          { offset: 1, color: '#F7B515' },
        ]),
      ],
      defaultUrl: '',
      data: '', // 个人资料
      fileList: [], // 上传的文件列表
      listdata: new FormData(),
      phone: '',
      NewParam: {
        phone: '',
        code: '', // 新手机号验证码
      },
      AuthCode: true, // 控制倒计时显示
      auth_time: 60, // 验证码倒计时
      AuthCode2: true, // 新手机号控制显示
      auth_time2: 60, // 新手机号倒计时
      oldCode: {
        code: '', // 原手机号验证码
      },
      loading: false, // 加载
      dialogNewPhone: false,
      dialog: {
        chack: false,
        edit: false,
        phone: false,
        password: false,
        info: false,
        title: {
          chack: '查看用户资料',
          edit: '编辑用户资料',
          phone: '编辑手机号',
          password: '编辑密码',
          info: '系统消息',
        },
        status: '',
      },
      // 密码
      param: {
        password: '',
        newPassword1: '',
        newPassword2: '',
      },
      change: {
        name: '',
        email: '',
        phone: '',
        code: '', // 新手机号验证码
      },
      infoData: [],
      info: {
        count: 10,
        page: 0,
        total: 0,
        type: '',
      },
      userInfo: {
        userName: '',
        url: '',
        phone: '',
        email: '',
        teamName: '',
      },
      passwordType: 'password',
      passwordType2: 'password',
      passwordType3: 'password',
      VUE_APP_USERCONSOLE: process.env.VUE_APP_USERCONSOLE,
      VUE_APP_HOME: process.env.VUE_APP_LOGIN,
      VUE_APP_LOGIN: process.env.VUE_APP_LOGIN,
      router: '',
      rules: {
        name: [{ required: true, trigger: 'change', message: '请输入账号昵称' }],
        code: [
          { required: true, trigger: 'change', message: '请输入验证码' },
          {
            pattern: /^\d{6}$/,
            message: '请输入验证码的正确格式',
            trigger: 'blur',
          },
        ],
        email: [
          { required: true, trigger: 'change', message: '请输入邮箱' },
          {
            pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: '请输入邮箱的正确格式',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
          {
            pattern: /^[\w!@#$%^&*]{6,16}$/,
            message: '请输入6-16个非空格字符',
            trigger: 'blur',
          },
        ],
        newPassword1: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            pattern: /^[\w!@#$%^&*]{6,16}$/,
            message: '请输入6-16个非空格字符',
            trigger: 'blur',
          },
        ],
        newPassword2: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: validatePass2, trigger: 'change' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3-9][0-9]{9}$/, message: '请输入正确手机号', trigger: 'blur' },
        ],
      },
    }
  },
  computed: {
    // 上传的地址
    Url() {
      return `${process.env.VUE_APP_API}/psl/user/url/modify`;
    },
  },
  created() {
    // if (cookies.get('teamId') === '128812f8-354f-470f-b8fa-c33ff62b51d1') {
    //   this.formDate.timeOeeWeekUp = 15.56
    //   this.formDate.timeOeeWeek = 31.50
    //   this.formDate.timeOeeWeekLast = 15.94
    //   this.formDate.timeOeeMonthUp = 7.32
    //   this.formDate.timeOeeMonthUpCount = 38650
    //   this.formDate.timeOeeMonth = 37.82
    //   this.formDate.timeOeeMonthLast = 30.50
    //   this.formDate.capacityOeeWeekUp = 5.55
    //   this.formDate.capacityOeeWeek = 74.86
    //   this.formDate.capacityOeeWeekLast = 69.31
    //   this.formDate.capacityOeeMonthUp = 6.45
    //   this.formDate.capacityOeeMonthUpCount = 19240
    //   this.formDate.capacityOeeMonth = 42.16
    //   this.formDate.capacityOeeMonthLast = 35.71
    //   this.formDate.monthScore = 82.50
    //   this.formDate.upperMonthScore = 80.4
    //   this.formDate.weekScore = 81.6
    //   this.formDate.upperWeekScore = 83.5
    //   this.formDate.firstScore = '--'
    //   this.formDate.deviceMonthScore = 85.6
    //   this.formDate.deviceUpperMonthScore = 83
    //   this.formDate.deviceFirstScore = null
    //
    //   // this.chartData3.source = [
    //   //   ['product', '-'],
    //   //   ...source2,
    //   // ];
    //   var myDate = new Date() // 获取今天日期
    //   myDate.setDate(myDate.getDate() - 4)
    //   var dateArray = []
    //   var dateTemp
    //   var flag = 1
    //   for (var i = 0; i < 4; i++) {
    //     dateTemp = myDate.getFullYear() + '-' +(myDate.getMonth() + 1) + '-' + myDate.getDate()
    //     dateArray.push(dateTemp)
    //     myDate.setDate(myDate.getDate() + flag)
    //   }
    //   const list = []
    //   dateArray.forEach((item,index) => {
    //     if (index === 0) {
    //       list.push({
    //         time: item,
    //         value: 80.7
    //       })
    //     }
    //     if (index === 1) {
    //       list.push({
    //         time: item,
    //         value: 82.3
    //       })
    //     }
    //     if (index === 2) {
    //       list.push({
    //         time: item,
    //         value: 83.9
    //       })
    //     }
    //     if (index === 3) {
    //       list.push({
    //         time: item,
    //         value: 79.5
    //       })
    //     }
    //
    //   })
    //   console.log(list)
    //   const source = []
    //   for (const line of list) {
    //     source.push([line.time, line.value])
    //   }
    //   this.chartData3.source = [
    //     ['product', '-'],
    //     ...source,
    //   ];
    //
    //
    //   myDate.setDate(myDate.getDate() - 7)
    //   var dateArray2 = []
    //   var dateTemp2
    //   var flag2 = 1
    //   for (var i = 0; i < 7; i++) {
    //     dateTemp2 = myDate.getFullYear() + '-' +(myDate.getMonth() + 1) + '-' + myDate.getDate() + '日'
    //     dateArray2.push(dateTemp2)
    //     myDate.setDate(myDate.getDate() + flag2)
    //   }
    //   console.log(dateArray2)
    //   const listDate = [
    //     {
    //       "name": "平均",
    //       "valueList": [
    //         {
    //           "date": null,
    //           "time": "2023-10-24",
    //           "value": "23.05",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-25",
    //           "value": "24.55",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-26",
    //           "value": "8.02",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-27",
    //           "value": "15.52",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-28",
    //           "value": "19.09",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-29",
    //           "value": "11.04",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-30",
    //           "value": "6.07",
    //           "data": null
    //         }
    //       ]
    //     },
    //     {
    //       "name": "印刷机3号",
    //       "valueList": [
    //         {
    //           "date": null,
    //           "time": "2023-10-24",
    //           "value": "2.57",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-25",
    //           "value": "1.74",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-26",
    //           "value": "4.79",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-27",
    //           "value": "3.13",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-28",
    //           "value": "0.00",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-29",
    //           "value": "0.00",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-30",
    //           "value": "2.01",
    //           "data": null
    //         }
    //       ]
    //     },
    //     {
    //       "name": "印刷机1号",
    //       "valueList": [
    //         {
    //           "date": null,
    //           "time": "2023-10-24",
    //           "value": "43.54",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-25",
    //           "value": "47.36",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-26",
    //           "value": "11.25",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-27",
    //           "value": "27.92",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-28",
    //           "value": "38.19",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-29",
    //           "value": "22.08",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-30",
    //           "value": "10.14",
    //           "data": null
    //         }
    //       ]
    //     }
    //   ]
    //   let legend = []
    //   let xData = []
    //   let dateData = []
    //   let series = []
    //   const sourceTime = []
    //   for (let i = 0; i < listDate.length; i++) {
    //
    //     let item = listDate[i]
    //     console.log(item)
    //     // for(const line of item.valueList) {
    //     //   sourceTime.push([line.time, line.value])
    //     // }
    //     legend.push(item.name)
    //     if(i===0){
    //       item.valueList.map((record, index) => {
    //         xData.push(dateArray2[index]);
    //         dateData.push(record.time)
    //       });
    //     }
    //     let newData = []
    //     for (let k=0;k<dateData.length;k++) {
    //       let flag=false
    //       for (let j=0;j<item.valueList.length;j++) {
    //         let subItem = item.valueList[j]
    //         if(dateData[k]==subItem.time){
    //           newData[k] = {date: subItem.time, time: subItem.time, value: subItem.value, data: null};
    //           flag= true
    //           break
    //         }
    //       }
    //       if(!flag){
    //         newData[k] = {date: dateData[k], value: null, data: null};
    //       }
    //     }
    //     series[i]={
    //       type:"line",
    //       // barMinWidth: '5px',
    //       name: item.name,
    //       data: newData
    //     }
    //   }
    //   this.chartData.source = [
    //     ['product', ...legend],
    //     ...sourceTime,
    //   ]
    //   this.chartData.series = series
    //   this.chartDataTime.legend = legend
    //   this.chartDataTime.series = series
    //   this.chartDataTime.xData = xData
    //
    //   let legend2 = []
    //   let xData2 = []
    //   let dateData2 = []
    //   let series2 = []
    //   const sourceNature = []
    //   const listDate2 = [
    //     {
    //       "name": "平均",
    //       "valueList": [
    //         {
    //           "date": null,
    //           "time": "2023-10-24",
    //           "value": "72.27",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-25",
    //           "value": "81.92",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-26",
    //           "value": "81.95",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-27",
    //           "value": "86.03",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-28",
    //           "value": "50.00",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-29",
    //           "value": "41.55",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-30",
    //           "value": "74.13",
    //           "data": null
    //         }
    //       ]
    //     },
    //     {
    //       "name": "印刷机3号",
    //       "valueList": [
    //         {
    //           "date": null,
    //           "time": "2023-10-24",
    //           "value": "62.03",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-25",
    //           "value": "69.91",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-26",
    //           "value": "76.01",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-27",
    //           "value": "77.96",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-28",
    //           "value": "0.00",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-29",
    //           "value": "0.00",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-30",
    //           "value": "75.50",
    //           "data": null
    //         }
    //       ]
    //     },
    //     {
    //       "name": "印刷机1号",
    //       "valueList": [
    //         {
    //           "date": null,
    //           "time": "2023-10-24",
    //           "value": "82.52",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-25",
    //           "value": "93.94",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-26",
    //           "value": "87.89",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-27",
    //           "value": "94.11",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-28",
    //           "value": "100.00",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-29",
    //           "value": "83.11",
    //           "data": null
    //         },
    //         {
    //           "date": null,
    //           "time": "2023-10-30",
    //           "value": "72.76",
    //           "data": null
    //         }
    //       ]
    //     }
    //   ]
    //   for (let i = 0; i < listDate2.length; i++) {
    //     let item = listDate2[i]
    //     // for(const line of item.valueList) {
    //     //   sourceNature.push([line.time, line.value])
    //     // }
    //     legend2.push(item.name)
    //     if(i===0){
    //       item.valueList.map((record, index) => {
    //         xData2.push(dateArray2[index]);
    //         dateData2.push(record.time)
    //       });
    //     }
    //     let newData2 = []
    //     for (let k=0;k<dateData2.length;k++) {
    //       let flag=false
    //       for (let j=0;j<item.valueList.length;j++) {
    //         let subItem = item.valueList[j]
    //         if(dateData2[k]==subItem.time){
    //           newData2[k] = {date: subItem.time, time: subItem.time, value: subItem.value, data: null};
    //           flag= true
    //           break
    //         }
    //       }
    //       if(!flag){
    //         newData2[k] = {date: dateData2[k], value: null, data: null};
    //       }
    //     }
    //     series2[i]={
    //       type:"line",
    //       // barMinWidth: '5px',
    //       name: item.name,
    //       data: newData2
    //     }
    //   }
    //   this.chartData2.source = [
    //     ['product', ...legend2],
    //     ...sourceNature,
    //   ]
    //   this.chartData2.series = series2
    //   this.chartDataTime2.legend = legend2
    //   this.chartDataTime2.series = series2
    //   this.chartDataTime2.xData = xData2
    //   this.formDate.person = 'alexwangs,张希玉,张在杰,印工社'
    //   this.formDate.name = '印刷机1号'
    //   this.formDate.groupName = '1号机A班'
    //   this.formDate.person = 'alexwangs,张希玉,张在杰,印工社'
    // } else {
      this.getList()
    // }
    // this.getList()
    this.currentTime()
    this.getTime()
    this.getUserInfo();
  },
  mounted() {

  },
  methods: {
    handleMouse() {
      this.fullScreenTrue = true
    },
    handleLeave() {
      this.fullScreenTrue = false
    },
    click() {
      if (this.isFullscreen) {
        this.isFullscreen = false
      } else {
        this.isFullscreen = true
      }
      let element = document.getElementById("box999"); //指定全屏区域元素
      screenfull.toggle(element); //全屏显示
    },
    getList() {
      API.DaPingLaoBan().then(res => {
        if (res.message.success) {
          this.formDate = res.message.data
          let legend = []
          let xData = []
          let dateData = []
          let series = []
          let legend2 = []
          let xData2 = []
          let dateData2 = []
          let series2 = []
          const sourceTime = []
          const sourceNature = []
         for (let i = 0; i < this.formDate.timeOee.length; i++) {
             let item = this.formDate.timeOee[i]
           // for(const line of item.valueList) {
           //   sourceTime.push([line.time, line.value])
           // }
             legend.push(item.name)
             if(i===0){
               item.valueList.map(record => {
                 xData.push(record.time + '日');
                 dateData.push(record.time)
               });
             }
           let newData = []
           for (let k=0;k<dateData.length;k++) {
             let flag=false
             for (let j=0;j<item.valueList.length;j++) {
               let subItem = item.valueList[j]
               if(dateData[k]==subItem.time){
                 newData[k] = {date: subItem.time, time: subItem.time, value: subItem.value, data: null};
                 flag= true
                 break
               }
             }
             if(!flag){
               newData[k] = {date: dateData[k], value: null, data: null};
             }
           }
           series[i]={
             type:"line",
             // barMinWidth: '5px',
             name: item.name,
             data: newData
           }
         }
          this.chartData.source = [
            ['product', ...legend],
            ...sourceTime,
          ]
          this.chartData.series = series
          for (let i = 0; i < this.formDate.capacityOee.length; i++) {
            let item = this.formDate.capacityOee[i]
            // for(const line of item.valueList) {
            //   sourceNature.push([line.time, line.value])
            // }
            legend2.push(item.name)
            if(i===0){
              item.valueList.map(record => {
                xData2.push(record.time  + '日');
                dateData2.push(record.time)
              });
            }
            let newData2 = []
            for (let k=0;k<dateData2.length;k++) {
              let flag=false
              for (let j=0;j<item.valueList.length;j++) {
                let subItem = item.valueList[j]
                if(dateData2[k]==subItem.time){
                  newData2[k] = {date: subItem.time, time: subItem.time, value: subItem.value, data: null};
                  flag= true
                  break
                }
              }
              if(!flag){
                newData2[k] = {date: dateData2[k], value: null, data: null};
              }
            }
            series2[i]={
              type:"line",
              // barMinWidth: '5px',
              name: item.name,
              data: newData2
            }
          }
          this.chartData2.source = [
            ['product', ...legend2],
            ...sourceNature,
          ]
          this.chartData2.series = series2
          this.chartDataTime.legend = legend
          this.chartDataTime.series = series
          this.chartDataTime.xData = xData
          this.chartDataTime2.legend = legend2
          this.chartDataTime2.series = series2
          this.chartDataTime2.xData = xData2
          const source2 = []
            for (const line of this.formDate.scoreList) {
              source2.unshift([line.time, line.value])
            }
          this.chartData3.source = [
            ['product', '-'],
            ...source2,
          ];
          this.chartData4.source = [
            [
              {
                name: 'typeA',
                type: 'pictorialBar',
                symbolClip: true,
                data: [
                  {
                    value: 34,
                  },
                  {
                    value: 100,
                  },
                  {
                    value: 89,
                  }
                ],
                z: 10
              },
              {
                name: 'full',
                type: 'pictorialBar',
                // animationDuration: 1,
                itemStyle: {
                  color: '#ccc'
                },
                data: [
                  {
                    value: 1,
                    // symbol: symbols[0],
                  },
                  {
                    value: 1,
                    // symbol: symbols[1]
                  },
                  {
                    value: 1,
                    // symbol: symbols[2]
                  },
                ]
              }
            ]
          ]
        }
      })
    },

    getDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let hour = date.getHours(); // 时
      let week = date.getDay(); // 星期
      let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      this.nowDate = `${hour}:${minute}:${second}`;
      this.nowDateList = `${year}.${month}.${day}`;
      this.nowDateWeek = `${weekArr[week]}`
    },
    getValue() {
      let date = new Date();
      let hour = date.getHours(); // 时
      if (hour <= 8) {
        this.formDate.timeOee = 5
        this.formDate.timeOeeDay = 5
      } else if(8 < hour && hour <= 9) {
        this.formDate.timeOee = 8.4
        this.formDate.timeOeeDay = 8.4
      } else if(9 < hour && hour <= 10) {
        this.formDate.timeOee = 11
        this.formDate.timeOeeDay = 11
      } else if(10 < hour && hour <= 11) {
        this.formDate.timeOee = 14.7
        this.formDate.timeOeeDay = 14.7
      } else if( 11 < hour && hour <= 12) {
        this.formDate.timeOee = 17.2
        this.formDate.timeOeeDay = 17.2
      } else if( 12 < hour && hour <= 13) {
        this.formDate.timeOee = 20
        this.formDate.timeOeeDay = 20
      } else if( 13 < hour && hour <= 14) {
        this.formDate.timeOee = 23.5
        this.formDate.timeOeeDay = 23.5
      } else if( 14 < hour && hour <= 15) {
        this.formDate.timeOee = 26.1
        this.formDate.timeOeeDay = 26.1
      } else if( 15 < hour && hour <= 16) {
        this.formDate.timeOee = 29
        this.formDate.timeOeeDay = 29
      } else if( 16 < hour && hour <= 17) {
        this.formDate.timeOee = 32.6
        this.formDate.timeOeeDay = 32.6
      }else if( 17 < hour && hour <= 18) {
        this.formDate.timeOee = 35
        this.formDate.timeOeeDay = 35
      }else if( 18 < hour && hour <= 19) {
        this.formDate.timeOee = 38.3
        this.formDate.timeOeeDay = 38.3
      }else if( 19 < hour) {
        this.formDate.timeOee = 42
        this.formDate.timeOeeDay = 42
      }
    },
    getTime() {
      setInterval(()=>{
        // if (cookies.get('teamId') !== '128812f8-354f-470f-b8fa-c33ff62b51d1'){
          // this.getValue()
          this.getList()
        // }
      },1000 * 3600)
    },
    currentTime(){
      setInterval(()=>{
        this.getDate()
      },1000)
    },
    handleIcon() {
      if (this.iconImg === true) {
        this.iconImg = false
      } else {
        this.iconImg = true
      }
    },
    handleIcon1() {
      if (this.iconImg === true) {
        this.iconImg = false
      }
    },

    // 返回portal首页
    ToHomepage() {
      // window.open(`${this.VUE_APP_HOME}`);
    },

    // 退出登录
    exit() {
      this.$confirm('确定要退出此用户?', '提示', {
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            store.dispatch('logout').then(() => {
              cookies.remove('token');
              window.open(`${this.VUE_APP_HOME}login/:SUP/`, '_self');
            });
          })
          .catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '已取消',
            // });
          });
    },
    // 获取个人信息
    getUserInfo() {
      store.dispatch('getInfo').then((res) => {
        console.log(res)
        if (res.message.success) {
          this.userInfo = res.message.data;
          // console.log(this.userInfo)
          this.defaultUrl = res.message.data.url;
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
* {
  //background-color: rgba(255,255,255,0);
  //
}
.max-width {
  width: 70%;
}
.max-width30 {
  width: calc(30% - 30px)
}
.max-width301 {
  width: calc(30% - 30px)
}
.max-width302 {
  display: none;
}
.max-width43 {
  width: 43%; margin-right: 30px
}
.max-width29 {
  width: 25%; margin-right: 30px
}
.box {
  position: relative;
  //padding: 20px;
  background-image: url("~@/assets/img/backImg.png");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 40px 40px;
  //height: 100%;
}
.title {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  //border-bottom: 1px solid #dcdddd;
  color: #fff;
  line-height: 61px;
}
.title img {
  width: 40px;
}
.time {
  position: absolute;
  right: 40px;
  top: 40px;
  display: flex;
  align-items: end;
  font-size: 24px;
  color: #3173F1;
}
.timeYear {
  position: absolute;
  left: 40px;
  top: 40px;
  display: flex;
  align-items: end;
  font-size: 20px;
  color: #333;
}
.timeWeek {
  position: absolute;
  left: 40px;
  top: 63px;
  display: flex;
  align-items: end;
  font-size: 20px;
  color: #333;
}
.boss {
  padding-top: 40px;
}
.subhead {
  font-size: 14px;
  color: #333;
  text-align: center;
  margin-top: 10px;
}
.title-module {
  background-image: url("~@/assets/img/backTitle.png");
  background-size: cover;
  background-position: center;
  height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-module2 {
  background-image: url("~@/assets/img/backTitle4.png");
  background-size: cover;
  background-position: center;
  height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-module3 {
  background-image: url("~@/assets/img/13.png");
  background-size: cover;
  background-position: center;
  height: 37px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-module4 {
  background-image: url("~@/assets/img/backTitle2.png");
  background-size: cover;
  background-position: center;
  height: 39px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-repeat: no-repeat;
}
.title-module10 div:first-child {
  margin-left: 16px;
  color: #333;
  font-size: 20px;
  font-weight: bold;
}
.title-module10 div:last-child {
  color: #3173F1;
  font-size: 12px;
}
.echarts-back {
background-color: rgba(255, 255, 255,0.2);
  margin-top: 5px;
  border-top: 1px solid #A5C4FF;
  box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);
}
.day7{
  font-size: 14px;
  color: #333;
  text-align: center;
  margin-top: 25px;
  //margin-bottom: 25px;
}
.echarts-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.echarts-box:nth-child(2) {
  margin-bottom: 15px;
}
.paper {
  display: flex;
  padding: 16px;
}
.contrast {
  width: 200px;
  font-size: 18px;
  color: #333;
  //padding-right: 5%;
 //margin-right: 5%;
  border-right: 1px solid rgba(55, 121, 245, 0.53);
}
.contrast div {
  color: #FE981D;
  font-size: 32px;
  display: inline-block;
}
.contrast img {
  width: 14px;
}
.score-time {
  display: flex;
  align-items: center;
}
.score-time:first-child{
  margin-bottom: 16px;
}
.score-time div:first-child{
  margin-right: 14px;
  font-size: 16px;
  color: #333;
}
.info {
  padding: 12px 0;
  text-align: center;
  border: 1px solid #8BB3FF;
  font-size: 12px;
}
::v-deep .el-progress-circle {
  width: 100px !important;
}
.quality-box {
  width: 100%;
}
//.quality-box div{
//  display: inline-block;
//}
.score {
  display: inline-block;
}
.scoreStyle {
  display: flex;
  justify-content: space-between;
}
.scoreStyle2 {
  position: relative;
  padding-right: 10px;
}
.scoreStyle2 img {
  position: absolute;
  width: 130px;
  height: 130px;
}
.scoreStyle2 .quan1 {
  top: 9%;
  left: 2.5%;
  z-index: 0;
}
.scoreStyle2 .quan2 {
  top: 9%;
  right: 36.3%;
  z-index: 0;
}
.scoreStyle2 .quan3 {
  right: 2%;
  top: 9%;
  z-index: 0;
}
.score-item {
  color: #333;
  width: 120px;
  font-size: 18px;
  border: 1px solid #8BB3FF;
  //padding: 0 23px 38px;
  text-align: center;
  border-radius: 6px 6px 6px 6px;
  height: 200px;
  //max-height: 200px;
  background: rgba(90, 146, 243, 0.05);
  position: relative;
}
.score-item div{
  position: relative;
  font-size: 62px;
  color: #3173F1;
  margin-bottom: 25px;
  margin-top: 40px;
  overflow: hidden;
  text-align: center;
  width: 100%;
  //white-space: nowrap;
  //text-overflow: ellipsis;
}
.width-number {
  width: 70px !important;
  overflow: hidden !important;
  margin: 40px auto 25px !important;
}
.posi {
  bottom: 0;
  font-size: 18px !important;
  color: #333 !important;
  margin-bottom: 25px !important;
  margin-top: 0px !important;
}

::v-deep .el-progress-bar__inner {
  top: -1px;
}
.fullScreen {
  display: none;
}
.active {
  display: block;
}
::v-deep .el-progress-bar__innerText {
  color: #000 !important;
}
.null {
position: absolute;
  top: 9%;
  right: 2%;
}
.width-300 {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis; white-space: nowrap;
  text-align: right;
}
.width-120{
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis; white-space: nowrap;
  text-align: right;
}
.width-24 {
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis; white-space: nowrap;
  text-align: right;
}
.img-top {
  position: absolute;width: 12px;top: 80px;right: 10px;
  z-index: 99999;
}
::v-deep .el-progress-bar__outer {
  //height: 12px !important;
}
::v-deep .el-progress-bar__innerText {
  line-height: 15px !important;
}
.header-box {
  display: none;
}
@media screen and (min-width: 2300px) {
  .score-item div{
    position: relative;
    font-size: 40px;
    color: #3173F1;
    margin-bottom: 25px;
    margin-top: 20px;
    overflow: hidden;
    text-align: center;
    width: 100%;
    //white-space: nowrap;
    //text-overflow: ellipsis;
  }
  .width-number {
    width: 70px !important;
    overflow: hidden !important;
    margin: 40px auto 25px !important;
  }
  .posi {
    bottom: 0;
    font-size: 18px !important;
    color: #333 !important;
    margin-bottom: 15px !important;
    margin-top: 0px !important;
  }
  .img-top {
    position: absolute;width: 12px;top: 65px;right: 10px;
    z-index: 99999;
  }
}
@media screen and (max-width:800px) {
  .header-box {
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    height: 300px;
    background: #3e2b25;
    box-shadow: 0px 2px 6px 3px #bcbcbc;
    padding: 0 50px;
    position: fixed;
    width: 100%;
    z-index: 99999;
    .menu-app {
      display: none;
      position: absolute;
      bottom: -750px;
      left: 0;
      background-color: #fff;
      z-index: 9999;
      width: 100%;
      border-bottom-left-radius: 100px;
      border-bottom-right-radius: 100px;
      box-shadow: 0rem 0px 18px 0px #aaa6a6;
      ul{
        list-style: none;
        padding: 0 100px;
        li {
          display: block;
          border-bottom: 1px solid #E1E1E1;
          font-size: 78px;
          color: #000;
          padding: 70px 0;
          text-align: center;
        }
        img {
          width: 70px;
          margin-right: 20px;
        }
        a{
          text-decoration: none;
          color: #000;
          display: inline-block;
          width: 600px;
          text-align: left;
        }
      }
    }
    .activeImg {
      display: block;
    }
    .right-box {
      float: right;
      //margin-right: 40px;
      height: 100%;
      display: flex;
      align-items: center;
    }
    #logo {
      //height: 62px;
      //width: 202px;
      text-align: center;
      .img {
        margin-top: 30px;
        //height: 41px;
        width: 300px;
      }
    }
    .head li {
      font-size: 88px;
      color: #fff !important;
      //width: 80px;
      //height: 62px;
      //line-height: 62px;
      position: relative;
      align-items: center;
      float: left;
      //font-weight: bold;
      // margin-right: 80px;
      cursor: pointer;
      display: flex;
      .exit {
        width: 120px;
        height: 57px;
        line-height: 57px;
      }
    }
    .icon-order {
      width: 100px;
      height: 70px;
      margin-left: 30px;
    }
    .exit:hover {
      color: #f3a000;
    }
    .head > li a:hover {
      color: #f3a000;
    }
    .head {
      .active {
        color: #fff;
        text-decoration: none;
        transition: all ease 0.3s;
      }
    }
    .UserName {
      width: 200px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      float: right;
      cursor: pointer;
      color: #231815;
      position: relative;
    }
    .UserName:hover .menu {
      display: block;
      transition: all ease 0.3s;
    }
    .information {
      width: 120px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      float: right;
      cursor: pointer;
      color: #231815;
    }
    .menu {
      display: none;
      position: absolute;
      top: 60px;
      right: 0px;
      border: 1px solid #9999;
      background: #ffffff;
      z-index: 9;
      .pullDown {
        width: 62px;
        height: 60px;
        line-height: 60px;
        text-decoration: none;
        display: block;
      }
      li {
        width: 62px;
        height: 60px;
        color: #231815;
      }
    }
    .pullDown:hover {
      color: #f3a000;
      transition: all ease 0.3s;
    }
    .menu > li:hover a {
      color: #f3a000;
      transition: all ease 0.3s;
    }
  }
  .max-width {
    width: 100%;
  }
  .max-width30 {
    width: 100%;
  }
  .max-width301 {
    display: none;
  }
  .max-width302 {
    display: block;
    width: 100%;
  }
  .max-width43 {
    width: 100%;
  }
  .max-width29 {
    width: 100%;
  }
  .box {
    position: relative;
    //padding: 20px;
    background-image: url("~@/assets/img/backBack.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0 ;
    //height: 100%;
  }
  .title-top {
    position: relative;
    top: -200px;
  }
  .title {
    font-size: 110px;
    font-weight: bold;
    text-align: center;
    //border-bottom: 1px solid #dcdddd;
    color: #fff;
    line-height: 270px;
    background-image: url("~@/assets/img/bcak-title.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    display: none;
  }
  .title img {
    width: 40px;
  }
  .time {
    position: absolute;
    left: 850px;
    top: 550px;
    align-items: end;
    font-size: 88px;
    display: inline-block;
    color: #333;
  }
  .timeYear {
    position: absolute;
    //left: 380px;
    top: 550px;
    display: inline-block;
    align-items: end;
    font-size: 88px;
    color: #333;
  }
  .timeWeek {
    position: absolute;
    left: 530px;
    top: 530px;
    display: inline-block;
    align-items: end;
    font-size: 88px;
    color: #333;
  }
  .boss {
    //padding-top: 40px;
    padding: 470px 40px 40px;
    //margin-top: 300px;
  }
  .subhead {
    font-size: 76px;
    color: #333;
    text-align: center;
    margin-top: 40px;
  }
  .title-module {
    background-image: url("~@/assets/img/8.png");
    background-size: 100% 100%;
    background-position: center;
    height: 180px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 88px;
  }
  .title-module2 {
    background-image: url("~@/assets/img/8.png");
    background-size: 100% 100%;
    background-position: center;
    height: 180px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 88px;
  }
  .title-module3 {
    background-image: url("~@/assets/img/8.png");
    background-size: 100% 100%;
    background-position: center;
    height: 180px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 88px;
    margin-top: 70px;
    margin-bottom: 30px;
  }
  .title-module4 {
    background-image: url("~@/assets/img/8.png");
    background-size: 100% 100%;
    background-position: center;
    height: 180px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-repeat: no-repeat;
    font-size: 88px;
  }
  .title-module10 div:first-child {
    margin-left: 50px;
    color: #333;
    font-size: 74px;
    font-weight: bold;
  }
  .title-module10 div:last-child {
    color: #3173F1;
    font-size: 50px;
    //display: none;
  }
  .echarts-back {
    background-color: rgba(255, 255, 255,0.2);
    margin-top: 5px;
    border-top: 1px solid #A5C4FF;
    box-shadow: 0px 0px 13px 0px rgba(90, 146, 243, 0.25);
  }
  .day7{
    font-size: 76px;
    color: #333;
    text-align: center;
    margin-top: 25px;
    //margin-bottom: 25px;
  }
  .echarts-box {
    display: block;
    //justify-content: space-between;
    margin-bottom: 5px;
  }
  .echarts-box:nth-child(2) {
    margin-bottom: 15px;
  }
  .paper {
    display: flex;
    padding: 30px;
  }
  .contrast {
    width: 900px;
    font-size: 78px;
    color: #333;
    //padding-right: 5%;
    //margin-right: 5%;
    border-right: 1px solid rgba(55, 121, 245, 0.53);
  }
  .contrast div {
    color: #FE981D;
    font-size: 148px;
    display: inline-block;
  }
  .contrast img {
    width: 55px;
  }
  .score-time {
    display: flex;
    align-items: center;
  }
  .score-time:first-child{
    margin-bottom: 16px;
  }
  .score-time div:first-child{
    margin-right: 14px;
    font-size: 78px;
    color: #333;
  }
  .info {
    padding: 25px 0;
    text-align: center;
    border: 1px solid #8BB3FF;
    font-size: 74px;
  }
  ::v-deep .el-progress-circle {
    width: 100px !important;
  }
  .quality-box {
    width: 100%;
  }
  //.quality-box div{
  //  display: inline-block;
  //}
  .score {
    display: inline-block;
  }
  .scoreStyle {
    display: flex;
    justify-content: space-between;
  }
  .scoreStyle2 {
    position: relative;
    padding-right: 10px;
  }
  .scoreStyle2 img {
    position: absolute;
    width: 130px;
    height: 130px;
  }
  .scoreStyle2 .quan1 {
    top: 10.4%;
    left: 0%;
    z-index: 0;
  }
  .scoreStyle2 .quan2 {
    top: 10.4%;
    right: 36.3%;
    z-index: 0;
  }
  .scoreStyle2 .quan3 {
    right: 2.3%;
    top: 10%;
    z-index: 0;
  }
  .score-item {
    color: #333;
    width: 330px;
    font-size: 100px;
    border: 1px solid #8BB3FF;
    //padding: 0 23px 38px;
    text-align: center;
    border-radius: 6px 6px 6px 6px;
    height: 500px !important;
    //max-height: 200px;
    background: rgba(90, 146, 243, 0.05);
    position: relative;
  }
  .score-item div{
    position: relative;
    font-size: 90px;
    color: #3173F1;
    margin-bottom: 25px;
    margin-top: 40px;
    overflow: hidden;
    text-align: center;
    width: 100%;
    //white-space: nowrap;
    //text-overflow: ellipsis;
  }
  .height-screen {
    height: 640px !important;
  }
  .width-number {
    width: 79% !important;
    overflow: hidden !important;
    margin: 100px auto 60px !important;
  }
  .posi {
    bottom: 0;
    font-size: 68px !important;
    color: #333 !important;
    margin-bottom: 25px !important;
    margin-top: 0px !important;
  }

  ::v-deep .el-progress-bar__inner {
    top: 0px;
  }
  .fullScreen {
    display: none;
  }
  .active {
    display: none;
  }
  ::v-deep .el-progress-bar__innerText {
    color: #000 !important;
    font-size: 58px;
  }
  .null {
    position: absolute;
    top: 9.6%;
    right: 2%;
  }
  .width-300 {
    width: 2000px;
    overflow: hidden;
    text-overflow: ellipsis; white-space: nowrap;
    text-align: right;
  }
  .width-120{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; white-space: nowrap;
    text-align: left;
  }
  .width-24 {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis; white-space: nowrap;
    text-align: right;
  }
  .img-top {
    position: absolute;width: 30px;top: 130px;right: 0px;
    z-index: 9999;
  }
  ::v-deep .el-progress-bar__outer {
    //height: 60px !important;
  }
  ::v-deep .el-progress-bar__innerText {
    line-height: 1 !important;
    display: block !important;
    //position: relative;
    //top: -0.3125rem;
  }
   .el-tooltip__popper{
    font-size: 100px !important;
  }
   .is-dark {
    font-size: 100px !important;
  }
}
</style>
